import React from 'react'
import Link from 'gatsby-link'

const TopNav = ({
  path,
  title,
  date,
  blurb,
  blurbImage,
}) => (
  <li key={path}>
    <div className="top-line">
      <h3><Link to={path}>{title}</Link></h3>
      <p className="published-on"><time>{date}</time></p>
    </div>
    <div>
      <a href={path}>
        <img alt="" src={blurbImage} />
      </a>
      <div>
        <p>{blurb}</p>
      </div>
    </div>
  </li>
)

export default TopNav
