import React from 'react'
import BlogEntry from '../components/blogEntry'
import Layout from '../components/layout'
import { graphql } from 'gatsby'

const Blog = ({ data, location }) => (
  <Layout location={location}>
    <ul className="blog-list">
    {
      data.allMarkdownRemark.edges.map((post, i) => (
        <BlogEntry key={i} {...post.node.frontmatter} />
      ))
    }
    </ul>
  </Layout>
)

export const pageQuery = graphql`
  query BlogQuery {
    allMarkdownRemark(limit: 10, sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          frontmatter {
            title
            path
            date
            blurb
            blurbImage
          }
        }
      }
    }
  }
`

export default Blog
